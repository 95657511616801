.TitleBlock {

    @include heading-subline(color(primary));

    &__title {
        font-size: font-size(4);
        font-weight: font-weight(bold);
    }

        p:first-of-type, em {
          font-weight: font-weight(bold);
    }

        img {
        height:auto !important;
        max-width: 420px !important;

        @include below(break(s)) {
            max-width: 100% !important;
        }
    }

    &.elements-secondary {

        @include heading-subline(color(secondary));

    }


    &.elements-tertiary {

        @include heading-subline(color(tertiary));

    }


}
