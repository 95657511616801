/*
	 Card Block
	 ========================================================================== */

.ArticleBlock {

    h1, h2, h3, h4, h5, h6 {
        font-family: font-family(primary);
                font-weight: font-weight(bold);

    }
    p:first-of-type {
          font-weight: font-weight(bold);
    }


    ul {
        padding-left: 0px;
        list-style-type: none;

        li {
            display: grid;
            grid-template-columns: 20px auto;
            justify-content: start;
            align-items: center;
            font-weight: bold;
            margin-bottom: 2px;
        }

        li::before {
            content: ">";
            align-self: baseline;
        }
    }

    .container {
        border-right: solid 1px color(white);
    }

    &.elements-primary {
        h1, h2, h3, h4, h5, h6 {
            color: color(primary);
        }

        ul {
            li::before {
                color: color(primary);
            }
        }
    }

    &.elements-secondary {
        h1, h2, h3, h4, h5, h6 {
            color: color(secondary);
        }
    }


    &.elements-tertiary {

        h1, h2, h3, h4, h5, h6 {
            color: color(tertiary);
        }
    }
}
