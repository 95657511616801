/*
	 Card Block
	 ========================================================================== */

.TextBlock {
    font-size: font-size(2);

    ul {
        padding-left: 0px;
        list-style-type: none;


        li {
            display: grid;
            grid-template-columns: 20px auto;
            justify-content: start;
            align-items: center;
            font-weight: bold;
            margin-bottom: 2px;
        }

        li::before {
            content: ">";
            color: color(primary);
            align-self: baseline;
        }
    }
}
