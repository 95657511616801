
.ListingBlock {

    &__title, &__date, &__heading {
        font-family: font-family(primary);
    }

    &__heading {
        color: color(primary);
        font-size: font-size(6);
    }

    &__title {
        font-size: font-size(2);
        color: color(primary);
    }

    &__action {
        font-size: font-size(1);
        font-weight: font-weight(bold);

        &::after {
            content: " >>";
            color: color(primary);
        }
    }

}
