/* ==========================================================================
	 Input
	 ========================================================================== */

.InputLabel {
}

.TextInput, .SelectInput {

	&:focus {
	}

	&.TextAreaInput {
	}
}

.Radio, .Checkbox {

	.Input {
	}
}
