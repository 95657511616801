/*
	 Card Block
	 ========================================================================== */

.ClientBlock {


	&__title {
		font-weight: bold;
		font-size: font-size(2);
	}


}

