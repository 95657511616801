/* ==========================================================================
	 Footer
	 ========================================================================== */

.Footer {
    &__contact {
        span {
            display: inline-block;
            width: 30px;
        }
        p {

            font-weight: font-weight(bold);
        }
    }
}
