/* ==========================================================================
	 Grid (< Mixins < Tools)
	 ========================================================================== */

@mixin grid-gallery($columns) {
	@include clearfix();

	.col {
		@if($columns > 1) {
			margin-bottom: gutter();
			@include gallery($grid-columns/$columns of $grid-columns);
			&:nth-last-child(-n+#{$columns}) {
				margin-bottom: 0;
			}
		} @else {
			margin-bottom: space(l);
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}

@mixin grid-ratio($left, $right) {
	$units: $grid-columns/($left + $right);
	.col {
		margin-bottom: 0;
		&:first-child {
			@include span($left * $units);
		}
		&:last-child {
			@include span(last $right * $units);
		}
	}
}
