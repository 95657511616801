/*
	 Card Block
	 ========================================================================== */

.HeroBlock {


	    &__title {
				font-family: font-family(primary);
          line-height: $line-height-heading;
    }

	    &__action {
        font-size: font-size(2);
        font-weight: bold;

        &::after {
            content: " >>";
            color: color(white);
        }
    }

}
