.LocationBlock {
    &__title {
        font-family: font-family(primary);
        font-size: font-size(6);
    }

    &__name {
        font-weight: font-weight(bold);
    }

    &__contact {
        span {
            display: inline-block;
            width: 30px;
        }

        p {

            font-weight: font-weight(bold);
        }
    }

}
