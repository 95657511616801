/* ==========================================================================
	 Variables (< Setup)
	 ========================================================================== */

@import "../../../config/design.config.yml"; // import config


//
// Colors
// ==========================================================================


$_colors: (); // create empty map

// iterate through all color sets in config and merge into $_colors
@each $key, $color-set in $color {
	$_colors: map-merge($_colors, map-get($color-set, "colors"));
}

// define color function
@function color($color-name) {
 @return map-get($_colors, $color-name);
}


//
// Typography
// ==========================================================================

// add stacks to defined families
@function font-family-to-stack($font-family: "sans-serif", $font-type: "sans-serif") {
	@if $font-type == "sans-serif" {
		@return unquote("#{$font-family}, Helvetica, Arial, sans-serif");
	}
	@if $font-type == "serif" {
		@return unquote("#{$font-family}, Times New Roman, Times, serif");
	}

	@return unquote("Helvetica, Arial, sans-serif");
}

$_families: map-get($font, "family");
$_fonts: map-get($_families, "definitions");
$_fonts: map-merge($_fonts, map-get($_families, "applications"));


@function font-family($id: copy) {
	$font: map-get($_fonts, $id);
	$family: map-get($font, "family");
	$type: map-get($font, "type");
	@return font-family-to-stack($family, $type);
}

@function font-size($size: 0) {
	$scale: map-get($font, "scale");
	@return map-get($scale, $size);
}

@function font-weight($weight: normal) {
	$weights: map-get($font, "weight");
	@return map-get($weights, $weight);
}

@function shadow($depth: base) {
	@return map-get($shadow, $depth);
}

// Sizes
$font-size-base: map-get($font, "base-size");

// Typgraphic scale
$font-sizes: map-get($font, scale);

// Line heights
$line-height-block-heading: 1.1;
$line-height-heading: 1.25;
$line-height-copy: 1.65;

$letter-spacing-medium: 0.1em;
$letter-spacing-wide: 0.3em;


// Weights
$font-weight-bold: bold;
$font-weight-normal: normal;

//
// Breakpoints
// ==========================================================================

@function break($break) {
		@return map-get(map-get($breakpoint, "definitions"), $break) or map-get(map-get($breakpoint, "applications"), $break);
}

$break-grid-keys: ("base": "");

// prepare map with grid breakpoints as key strings for col classes
@each $key, $value in map-get($breakpoint, "applications") {
	$break-grid-keys: map-merge($break-grid-keys, ($key: $key+"-"));
}


$break-mobile: break(s);
$break-tablet: break(m);

/*
	 Spacing
	 ========================================================================== */

 @function space($break) {
		@return map-get($space, $break);
 }

$space-values: $space;

/*
	 Tokens
	 ========================================================================== */

// border
$border-ui: 1px solid color(primary);

// border radiuses
$border-radius-unit-small: 0;
$border-radius-bottom: 0 0 $border-radius-unit-small $border-radius-unit-small;

// shadows
$shadow-ui: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
$shadow-backdrop: 0 5px 30px 0px rgba(0, 0, 0, 0.1);



/*
	 Animation
	 ========================================================================== */
$transition-base: 0.25s ease-in;

/*
	 Elements
	 ========================================================================== */




// Input
$input-radius: $border-radius-unit-small;

/*
	 Components
	 ========================================================================== */

// Header
$header-logo-width: 200px;
$header-height: 150px;

// Buttons
$button-radius: $border-radius-unit-small;

/*
	 Blocks
	 ========================================================================== */

// Hero
$hero-block-container-ratio: 3;

// Feature
$feature-block-image-ratio: 3/2;
$feature-block-image-shadow: $shadow-ui;
