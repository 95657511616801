/* ==========================================================================
	 Form
	 ========================================================================== */

.Form {
    .form-control {
        background: color(info);
        border-color: color(primary);
        border-width: 2px;
    }

    .btn {
        min-width: 120px;
        font-weight: font-weight(bold);
    }
}

.freeform-row .freeform-column {
    padding: 10px 0 0 0 !important;
    margin: 0 15px 0 15px !important;
}
