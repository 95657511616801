/* ==========================================================================
	 Typography (< Mixins < Tools)
	 ========================================================================== */

 //*
 /// Sets responsive font size. It will set the requested font size on the
 /// desktop but scale it down for lower breakpoints
 ///
 /// @param {number} $size size as specified in variables/$font-sizes
 /// @return {null}
 @mixin font-size($size, $responsive: true) {
	 $index: index(map-keys($font-sizes), $size);
	 $base-index: index(map-keys($font-sizes), 0);

	 @if map-has-key($font-sizes, $size) {
		 @if($responsive) {
			 @if $index > $base-index { // if greater than base size, scale responsively

				 font-size: map-get($font-sizes, if($index - 2 > $base-index, $size - 2, 0));

				 @include above(break(l)) {
						font-size: map-get($font-sizes, if($index - 1 > $base-index, $size - 1, 0));
				 }

				 @include above(break(xl)) {
					 font-size: map-get($font-sizes, $size);
				 }
			 } @else { // if base size or smaller, leave as is
				 font-size: map-get($font-sizes, $size);
			 }
		 } @else {
			 font-size: map-get($font-sizes, $size);
		 }
	 }
 }

 @mixin link-color($color, $treatment: "none") {
	 &, &:visited, &:hover {
		 color: $color;
	 }

	 @if($treatment == "darken") {
		 &:hover {
			 color: darken($color, 5%);
		 }
	 }

	 @if($treatment == "lighten") {
		 &:hover {
			 color: lighten($color, 10%);
		 }
	 }
 }


@mixin heading-subline ($color){
	h1, h2, h3, h4 {
			margin-bottom: 2rem !important;
			position: relative;

			&::before {
				position: absolute;
				width: 100%;
				left: 0;
				bottom: -0.3em;
				padding: 0.5em 0 0;
				border-bottom: 0.15em solid $color;

				content: ' ';
			}
	}
}
