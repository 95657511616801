/*
	 Card Block
	 ========================================================================== */

.CardBlock {

	@include heading-subline(color(primary));

	.card-link {
		font-weight: bold;
		&::after {
        content: " >>";
        color: color(primary);
    }
	}


    &.elements-secondary {

        @include heading-subline(color(secondary));

        .card-link  {
            &::after {
                color: color(secondary);
            }
        }
    }

    &.elements-tertiary {

        @include heading-subline(color(tertiary));

        .card-link {
            &::after {
                color: color(tertiary);
            }
        }
    }



}
