/*
	 Card Block
	 ========================================================================== */

.FeatureBlock {

    @include heading-subline(color(primary));
      font-size: font-size(2);
    &__title {
        font-weight: font-weight(bold);
        color: color(primary);
    }

    &__action {
        font-weight: font-weight(bold);

        &::after {
            content: " >>";
            font-weight: font-weight(bold);
            color: color(primary);
        }
    }


    &.elements-secondary {

        @include heading-subline(color(secondary));

        .FeatureBlock__title {
            color: color(secondary);
        }
        .FeatureBlock__action {
            color: color(secondary);
            &::after {
                color: color(secondary);
            }
        }
    }

    &.elements-tertiary {

        @include heading-subline(color(tertiary));

        .FeatureBlock__title {
            color: color(tertiary);
        }
        .FeatureBlock__action {
            color: color(tertiary);
            &::after {
                color: color(tertiary);
            }
        }
    }


}
