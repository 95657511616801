.HeadingBlock {

    &__title {
        font-family: font-family(primary);
        font-size: font-size(6);
    }

    p:first-of-type, em {
        font-weight: font-weight(bold);
    }

    &__copy {
        h1, h2, h3, h4, h5, h6 {
            font-family: font-family(primary);
        }

        h3 {
            font-size: font-size(5);
        }
    }

    &.elements-primary {
        .HeadingBlock__title {
            color: color(primary);
        }
    }

    &.elements-secondary {

        .HeadingBlock__title {
            color: color(secondary);
        }

    }


    &.elements-tertiary {

        .HeadingBlock__title {
            color: color(tertiary);
        }

    }


}
