/* ==========================================================================
	 Navigation
	 ========================================================================== */

.Navigation {

}

.navbar-nav, .nav {
    li {
        margin-left: 0.75rem!important;
    }
    li:not(:last-child) {

         margin-right: 0.75rem!important;
    }
     .nav-link:last-child {

         padding-right: 0 !important;
    }
    .active{
        font-weight: font-weight(bold)
    }
}
