/* ==========================================================================
	 Card
	 ========================================================================== */


.Card {

	&:hover {
	}


	.Card__image {
	}

	.Card__action {
	}

	.Card__title {
	}

	.Card__copy {
	}



	&.Card--theme {

		.Card__text {
			&, p {
			}
		}

		.Card__title {
		}
	}

}
