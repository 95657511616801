/*
	 Card Block
	 ========================================================================== */

.CallToActionBlock {

	&__title {

		&::after {
        content: " >>";
        color: color(white);
    }
	}

}
