/*
	 Card Block
	 ========================================================================== */

.CaseStudyBlock {

    &__title {
        color: color(primary);
        font-family: font-family(primary);
        font-size: font-size(6);
    }

    p:first-of-type, em {
        font-weight: font-weight(bold);
    }



    ul {
        padding-left: 0px;
        list-style-type: none;

        li {
            display: grid;
            grid-template-columns: 20px auto;
            justify-content: start;
            align-items: center;
            font-weight: bold;
            margin-bottom: 2px;
        }

        li::before {
            content: ">";
            color: color(primary);
            align-self: baseline;
        }
    }

    &__main {
        @include heading-subline(color(primary));
    }

    &__sidebar {
        @include heading-subline(color(primary));


    }

    &.elements-secondary {
        .CaseStudyBlock__title, a {
            color: color(secondary);
        }

        .CaseStudyBlock__main, .CaseStudyBlock__sidebar {
            @include heading-subline(color(secondary));
        }

        ul {
            li::before {
                color: color(secondary);
            }
        }


    }

    &.elements-tertiary {
        .CaseStudyBlock__title, a {
            color: color(tertiary);
        }

        .CaseStudyBlock__main, .CaseStudyBlock__sidebar {
            @include heading-subline(color(tertiary));
        }

        .CaseStudyBlock__sidebar {
            ul {
                li::before {
                    color: color(tertiary);
                }
            }
        }

    }
}
