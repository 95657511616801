/*
	 Card Block
	 ========================================================================== */

.NameBlock {

	.NameBlock__title {
	}

	.NameBlock__card {

			.Card__title {
			}

			.Card__summary {
			}

			.Card_button {
			}

	}
}
